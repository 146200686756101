$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #ffffff; //
$COLFLAT2: #ffffff; //
$COL3: #000000; //
$COLFLAT3: #000000; //
$COL4: #afbd21; //
$COLFLAT4: #afbd21; //
$COL5: #ffffff; //
$COLFLAT5: #ffffff; //
$COL6: #111111; //
$COLFLAT6: #111111; //
$COL7: #111111; //
$COLFLAT7: #111111; //
$COL8: #111111; //
$COLFLAT8: #111111; //
$COL9: #111111; //
$COLFLAT9: #111111; //
$COL10: #111111; //
$COLFLAT10: #111111; //
$COL11: #999999; //
$COLFLAT11: #999999; //
$COL12: #111111; //
$COLFLAT12: #111111; //
$COL13: #e8e8e8; //
$COLFLAT13: #e8e8e8; //
$COL14: #f2f2f2; //
$COLFLAT14: #f2f2f2; //
$COL15: #cccccc; //
$COLFLAT15: #cccccc; //
$COL16: rgba(0,0,0,0.69); //Black 70%
$COLFLAT16: #282828; //Black 70%
$COL17: #111111; //footer black
$COLFLAT17: #111111; //footer black
$COL18: #666666; //Footer Heading
$COLFLAT18: #666666; //Footer Heading
$COL19: #333333; //join button
$COLFLAT19: #333333; //join button
$COL20: #1b1b1b; //dark
$COLFLAT20: #1b1b1b; //dark
$COL21: #b7904b; //Gold
$COLFLAT21: #b7904b; //Gold
$COL22: #000000; //Black
$COLFLAT22: #000000; //Black
$COL23: rgba(255,255,255,0.949); //white 95%
$COLFLAT23: #f9f9f9; //white 95%
$COL24: rgba(255,255,255,0.702); //white 70%
$COLFLAT24: #d9d9d9; //white 70%
$COL25: #dddddd; //phone button
$COLFLAT25: #dddddd; //phone button
$COL26: rgba(0,0,0,0.078); //black 0.05%
$COLFLAT26: #767676; //black 0.05%
$COL27: #aaaaaa; //grey
$COLFLAT27: #aaaaaa; //grey
$COL28: rgba(255,255,255,0.161); //white 10%
$COLFLAT28: #949494; //white 10%
$COL29: #f2f2f2; //header border
$COLFLAT29: #f2f2f2; //header border
$COL30: rgba(248,248,248,0.039); //side menu hover
$COLFLAT30: #858585; //side menu hover
$COL31: #222222; //
$COLFLAT31: #222222; //
$COL32: #111111; //
$COLFLAT32: #111111; //
$COL33: #111111; //
$COLFLAT33: #111111; //
$COL34: #000000; //
$COLFLAT34: #000000; //
$COL35: #111111; //
$COLFLAT35: #111111; //
$COL36: #111111; //
$COLFLAT36: #111111; //
$COL37: #ffffff; //
$COLFLAT37: #ffffff; //
$COL38: #f2f2f2; //
$COLFLAT38: #f2f2f2; //
$COL39: #afbd21; //Green
$COLFLAT39: #afbd21; //Green
$COL40: #e9e9e8; //content grey
$COLFLAT40: #e9e9e8; //content grey
$COL41: #777575; //Link Colour
$COLFLAT41: #777575; //Link Colour
$COL42: #0160a4; //Blue ANDA
$COLFLAT42: #0160a4; //Blue ANDA
$COL43: #222021; //
$COLFLAT43: #222021; //
$COL44: #63bae9; //
$COLFLAT44: #63bae9; //
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1280px;
