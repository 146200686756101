.prod {
  .me-Hamle{
    .row{
      margin: 10px auto 0;
      max-width: 60rem;
    }
    .columns{
      align-items: center;
      @media screen and (min-width: 640px) {align-items: flex-start;}
      @media screen and (max-width: 640px) {text-align: center;}
      &:first-child{
        color: #666666;
        align-items: center;
        @media screen and (min-width: 640px) {
          align-items: flex-end;
        }
      }
    }
  }
  .head, .body{max-width: 100%;}
  .head {
    color:white;
    background: #333333;
    max-width: 100%;
    width: 100%;
    padding: 10px;
    z-index:1;
    .columns{
      flex-direction: row;
      justify-content: center;
      border-left: 1px dashed white;
      span{
        cursor:pointer;
        font-size: 14px;
        margin: 0 5px;
      }
    }
  }
  .body{
    background-color:white;
    margin:0;
    max-height:0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    span.close{
      cursor: pointer;
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: #333333;
      z-index:0;
    }
    .columns{
      padding: 30px 60px;
      color: #333333;
      font-size: 14px;
    }
    &.open{
      transition: max-height 0.5s ease;
      max-height:1000px;
    }
  }
}

/* As per instruction from KingKong */
#read-more { display: none; }