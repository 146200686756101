body { background-color: $COL2; }
body { color: $COL3; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL7;
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
}
h2 {
color: $COL8;
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 22px;

@media #{$large-up} {
font-size: 27px;

}
}
h3 {
color: $COL9;
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
h4 {
color: $COL10;
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
h5 {
color: $COL32;
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
h6 {
color: $COL33;
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
.button {
font-family: 'Khula';
font-weight: 600;
font-style: normal;
line-height: 1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL35;
font-family: 'Khula';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL34;
font-family: 'Cinzel';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
summary {
font-family: 'Merriweather';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Khula';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Khula';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: $COL4}
a:hover {color: $COL11}
/* Email template menu:52 */
.MES52 {
background-color: $COL2;
color: $COL12;
font-size: 14.4px;
padding: 20px 10px 0 10px;

 }
.MES52 {
background-color: $COL2;
color: $COL12;
font-size: 14.4px;
padding: 20px 10px 0 10px;

h1.MEC52, h2.MEC52, h3.MEC52, h4.MEC52, h5.MEC52, h6.MEC52 { color: $COL12;
 }
 }
a.MEC52 { color: $COL42;
&:hover { color: $COL44; }
 }
.MEC52 li { 
font-size: 14.4px;

 }
cite.MEC52{
color: $COL12;
font-size: 14.4px;
}
/* Accordion:38 */
details.MES38 {
& > summary{background-color: $COL37;
}
color: $COL36;
& > article { color: $COL36;
 }
 }
/* Accordion style:39 */
.MES39 {
background-color: $COL2;
color: $COL12;
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
.MES39 {
background-color: $COL2;
color: $COL12;
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
a.MEC39 { color: $COL41;
&:hover { color: $COL39; }
 }
cite.MEC39{
color: $COL12;
}
/* newslist:40 */
.MES40 {
padding: 15px 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL15;}
& > hr {border-top-style: solid;}
 }
/* Accordion:41 */
details.MES41 {
& > summary{background-color: $COL37;
}
color: $COL36;
& > article { color: $COL36;
 }
& > summary { color: $COL12;
font-size:19.18px;
 }
 }
/* Buying and selling:42 */
.MES42 {
padding: 10px 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL20;}
& > hr {&:hover { border-color: $COL20;} }
& > hr {border-top-style: solid;}
 }
/* Feature Coins:43 */
.MES43 {
 }
.MES43 {
 }
/* View E Catalogue:44 */
.MES44 {
background-color: $COL16;
color: $COL2;
&:hover { color: $COL2;}
font-size: 14.4px;
border-radius: 5px;
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: $COL14;
 }
/* Enquire Button Black:45 */
.MES45 {
background-color: $COL12;
&:hover { background-color: $COL18;}
color: $COL2;
&:hover { color: $COL2;}
font-size: 18px;
border-radius: 0 15px;
padding: 10px 20px;

border-width: 2px;
border-style: solid;
border-color: $COL2;
 }
/* Home Slider Padding:46 */
.MES46 {
color: $COL18;
padding: 15px;

@media #{$medium-up} {
padding: 18px 15px;

}
 }
.MES46 {
color: $COL18;
padding: 15px;

@media #{$medium-up} {
padding: 18px 15px;

}
 }
cite.MEC46{
color: $COL18;
}
/* Slider wrap:47 */
.MES47 {
background-color: $COL13;
padding: 0;

 }
.MES47 {
background-color: $COL13;
padding: 0;

 }
/* Homepage Bottom Line:48 */
.MES48 {
padding: 5px 0 0 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL20 transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Popup Join Form:49 */
.MES49 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://coinworks.com.au/img/6043/4167');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL2;
padding: 30px 15px;

 }
.MES49 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://coinworks.com.au/img/6043/4167');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL2;
padding: 30px 15px;

 }
cite.MEC49{
color: $COL2;
}
/* Popup Join Form:50 */
.me-block.me-Form.MES50,  body.MES50 {background-color:transparent;
  
 input[type='submit']{border-style: solid;background-color: $COL2;
&:hover {background-color: $COL19}
border-color: $COL12;
border-width: 1px;
color: $COL2;
&:hover {color: $COL2}
padding: 10px 50px 5px 50px;

border-radius: 0 20px;
}
@include placeholder($COL2);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
border-color: $COL2;
background-color: $COL26;
color: $COL2;
}
::-webkit-input-placeholder { color: $COL2; opacity: 0.5;}
:-moz-placeholder {color: $COL2; opacity: 0.5;} 
::-moz-placeholder {color: $COL2; opacity: 0.5;}
:-ms-input-placeholder {color: $COL2; opacity: 0.5;}

  }
/* Page product panel:51 */
.MES51 {
background-color: $COL13;
&:hover, &.hover { background-color: $COL13;}
color: $COL12;
font-size: 12.8px;
padding: 10px 10px 0 10px;

 }
.MES51 {
background-color: $COL13;
&:hover, &.hover { background-color: $COL13;}
color: $COL12;
font-size: 12.8px;
padding: 10px 10px 0 10px;

 }
cite.MEC51{
color: $COL12;
font-size: 12.8px;
}
/* Email seperator:53 */
.MES53 {
padding: 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL20 transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Dark:36 */
.MES36 {
background-color: $COL31;
 }
/* Request Catalogue Button:62 */
.MES62 {
background-color: $COL39;
&:hover { background-color: $COL18;}
color: $COL37;
&:hover { color: $COL2;}
font-size: 18px;
border-radius: 0 20px;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: $COL2;
 }
/* Go Back Button:68 */
.MES68 {
background-color: $COL1;
&:hover { background-color: $COL18;}
color: $COL2;
&:hover { color: $COL15;}
font-size: 18px;
border-radius: 0 20px;
padding: 10px 40px 5px 40px;

border-width: 1px;
border-style: solid;
border-color: $COL2;
 }
/* Product Accrodion grey:67 */
details.MES67 {
& > summary{background-color: $COL13;
}
& > summary{padding: 20px 15px;}

@media #{$medium-up} {
& > summary{padding: 20px 25px;}

}
@media #{$large-up} {
& > summary{padding: 20px 45px;}

}
& > article{padding: 0 10px 20px 15px;}

@media #{$medium-up} {
& > article{padding: 0 20px 20px 25px;}

}
@media #{$large-up} {
& > article{padding: 0 40px 20px 45px;}

}
& > summary { font-size:19.18px;
 }
 }
/* Related Hover:66 */
.MES66 {
&:hover, &.hover { background-color: $COL14;}
 }
.MES66 {
&:hover, &.hover { background-color: $COL14;}
 }
/* Product Accrodion:65 */
details.MES65 {
& > summary{padding: 20px 15px;}

@media #{$medium-up} {
& > summary{padding: 20px 25px;}

}
@media #{$large-up} {
& > summary{padding: 20px 45px;}

}
& > article{padding: 0 10px 20px 15px;}

@media #{$medium-up} {
& > article{padding: 0 20px 20px 25px;}

}
@media #{$large-up} {
& > article{padding: 0 40px 20px 45px;}

}
& > summary { font-size:19.18px;
 }
 }
/* coin mask:64 */
.MES64 {
background-color: $COL16;
color: $COL2;
 }
.MES64 {
background-color: $COL16;
color: $COL2;
h1.MEC64, h2.MEC64, h3.MEC64, h4.MEC64, h5.MEC64, h6.MEC64 { color: $COL2;
 }
 }
cite.MEC64{
color: $COL2;
}
/* Request Catalogue:63 */
.MES63 {
background-color: $COL39;
&:hover, &.hover { background-color: $COL18;}
color: $COL2;
border-radius: 0 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: $COL2;
 }
.MES63 {
background-color: $COL39;
&:hover, &.hover { background-color: $COL18;}
color: $COL2;
border-radius: 0 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: $COL2;
 }
cite.MEC63{
color: $COL2;
}
/* Site Header:61 */
.MES61 {
padding: 30px 0;

@media #{$medium-up} {
padding: 0 0 20px 0;

}
@media #{$large-up} {
padding: 0 0 30px 0;

}
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL20;}
& > hr {border-top-style: solid;}
 }
/* :54 */
.MES54 {
 }
.MES54 {
 }
/* Light Blue ANDA:60 */
.MES60 {
background-color: $COL44;
color: $COL2;
padding: 15px;

 }
.MES60 {
background-color: $COL44;
color: $COL2;
padding: 15px;

 }
cite.MEC60{
color: $COL2;
}
/* Shade 3:59 */
.MES59 {
background-color: $COL44;
 }
.MES59 {
background-color: $COL44;
 }
/* Shade 3:58 */
.MES58 {
background-color: $COL44;
 }
/* Shade 2:57 */
.MES57 {
background-color: $COL43;
 }
.MES57 {
background-color: $COL43;
 }
/* Shade 2:56 */
.MES56 {
background-color: $COL43;
 }
/* Blue ANDA:55 */
.MES55 {
background-color: $COL42;
min-height:50px;
@media #{$medium-up} {
min-height: 50px;};
@media #{$large-up} {
min-height: 50px;};
padding: 15px;

 }
.MES55 {
background-color: $COL42;
min-height:50px;
@media #{$medium-up} {
min-height: 50px;};
@media #{$large-up} {
min-height: 50px;};
padding: 15px;

 }
/* Dark:37 */
.MES37 {
background-color: $COL31;
 }
.MES37 {
background-color: $COL31;
 }
/* Page Separator:35 */
.MES35 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL20 transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Primary:2 */
.MES2 {
background-color: $COL12;
color: $COL5;
padding: 5px 15px;

 }
/* Photo Button:17 */
.MES17 {
background-color: $COL25;
&:hover { background-color: $COL20;}
color: $COL22;
&:hover { color: $COL14;}
padding: 5px 10px;

border-width: 1px;
border-style: solid;
border-color: $COL13;
 }
/* Primary:3 */
.MES3 {
background-color: $COL12;
color: $COL5;
 }
.MES3 {
background-color: $COL12;
color: $COL5;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL5;
 }
 }
cite.MEC3{
color: $COL5;
}
/* Secondary:4 */
.MES4 {
background-color: $COL13;
color: $COL6;
 }
/* Secondary:5 */
.MES5 {
background-color: $COL13;
color: $COL6;
 }
.MES5 {
background-color: $COL13;
color: $COL6;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL6;
 }
 }
cite.MEC5{
color: $COL6;
}
/* Alternate:6 */
.MES6 {
background-color: $COL14;
 }
/* Alternate:7 */
.MES7 {
background-color: $COL2;
color: $COL12;
 }
.MES7 {
background-color: $COL2;
color: $COL12;
 }
cite.MEC7{
color: $COL12;
}
/* Light:8 */
.MES8 {
background-color: $COL15;
 }
/* Light:9 */
.MES9 {
background-color: $COL15;
 }
.MES9 {
background-color: $COL15;
 }
/* Header:10 */
.MES10 {
background-color: $COL23;
color: $COL12;
@media #{$large-up} {
min-height: 50px;};
 }
.MES10 {
background-color: $COL23;
color: $COL12;
@media #{$large-up} {
min-height: 50px;};
h1.MEC10 { @media #{$large-up} { font-size: 28.5px; }; }
h2.MEC10 { @media #{$large-up} { font-size: 25.65px; }; }
h3.MEC10 { @media #{$large-up} { font-size: 17.1px; }; }
h4.MEC10 { @media #{$large-up} { font-size: 13.3px; }; }
h5.MEC10 { @media #{$large-up} { font-size: 15.2px; }; }
h6.MEC10 { @media #{$large-up} { font-size: 13.3px; }; }
 }
a.MEC10 { color: $COL12;
 }
cite.MEC10{
color: $COL12;
}
/* Main Menu:11 */
nav.me-Menu.MES11 {
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: $COL12;
font-size: 14.4px;
text-transform: uppercase;
}
 &:hover > a.MEC11{color: $COL19;
}
 }
&.horizontal > .menu-item.MEC11 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC11 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC11 .menu-item { border:0;
border-bottom-width: 1px;
border-color: $COL25;
border-style: solid;
 }
&.horizontal > .menu-item.MEC11 .sub-menu { border:1px;
border-color: $COL13;
border-style: solid;
 }
& > .menu-item > a{padding: 10px 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

@media #{$medium-up} {
& .sub-menu .menu-item a{padding: 15px 10px;}

}


&.horizontal .menu-item.MEC11:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL2;}}
&.vertical .menu-item.MEC11:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL2;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC11{background-color: $COL2;
 &:hover {background-color: $COL29}
}
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: $COL31;
text-transform: uppercase;
}
  }

}
}
 }
/* footer:12 */
.MES12 {
background-color: $COL17;
color: $COL15;
padding: 40px 0 0 0;

 }
.MES12 {
background-color: $COL17;
color: $COL15;
padding: 40px 0 0 0;

h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: $COL15;
 }
 }
a.MEC12 { color: $COL15;
&:hover { color: $COL39; }
 }
cite.MEC12{
color: $COL15;
}
/* Footer Separator:13 */
.MES13 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL14;}
& > hr {border-top-style: dotted;}
 }
/* Footer Menu:14 */
nav.me-Menu.MES14 {
& .menu-item.MEC14, & .menu-item.MEC14 > div.MEC14{ & > a.MEC14{color: $COL18;
}
 &:hover > a.MEC14{color: $COL14;
}
 }
&.horizontal > .menu-item.MEC14 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC14 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC14 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC14 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Join Form:15 */
.me-block.me-Form.MES15,  body.MES15 {background-color:transparent;
 color: $COL2;
 
 input[type='submit']{border-style: solid;background-color: $COL1;
&:hover {background-color: $COL19}
border-color: $COL2;
color: $COL2;
&:hover {color: $COL2}
padding: 10px 50px 5px 50px;

border-radius: 1px 20px;
}
@include placeholder($COL20);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
border-color: $COL1;
background-color: $COL2;
color: $COL20;
}
::-webkit-input-placeholder { color: $COL20; opacity: 0.5;}
:-moz-placeholder {color: $COL20; opacity: 0.5;} 
::-moz-placeholder {color: $COL20; opacity: 0.5;}
:-ms-input-placeholder {color: $COL20; opacity: 0.5;}

  }
/* photo:16 */
.MES16 {
background-color: $COL2;
color: $COL22;
padding: 2px;

@media #{$medium-up} {
padding: 10px;

}
border-width: 1px;
border-style: solid;
border-color: $COL13;
 }
.MES16 {
background-color: $COL2;
color: $COL22;
padding: 2px;

@media #{$medium-up} {
padding: 10px;

}
border-width: 1px;
border-style: solid;
border-color: $COL13;
 }
a.MEC16 { color: $COL22;
&:hover { color: $COL39; }
 }
cite.MEC16{
color: $COL22;
}
/* Read More:18 */
.MES18 {
background-color: $COL13;
&:hover { background-color: $COL13;}
color: $COL19;
&:hover { color: $COL19;}
padding: 5px 10px;

 }
/* News list item:34 */
.MES34 {
background-color: $COL1;
&:hover, &.hover { background-color: $COL19;}
padding: 15px;

border-width: 0 1px 1px 1px;
border-style: solid;
border-color: $COL19;
 }
.MES34 {
background-color: $COL1;
&:hover, &.hover { background-color: $COL19;}
padding: 15px;

border-width: 0 1px 1px 1px;
border-style: solid;
border-color: $COL19;
 }
a.MEC34 { color: $COL22;
&:hover { color: $COL14; }
 }
/* Contact Form:27 */
.me-block.me-Form.MES27,  body.MES27 {background-color:transparent;
  
 input[type='submit']{border-style: solid;background-color: $COL39;
&:hover {background-color: $COL41}
border-width: 0;
color: $COL14;
&:hover {color: $COL14}
padding: 14px 50px 10px 50px;

border-radius: 1px 20px;
}
@include placeholder($COL19);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
border-color: $COL15;
color: $COL19;
}
::-webkit-input-placeholder { color: $COL19; opacity: 0.5;}
:-moz-placeholder {color: $COL19; opacity: 0.5;} 
::-moz-placeholder {color: $COL19; opacity: 0.5;}
:-ms-input-placeholder {color: $COL19; opacity: 0.5;}

  }
/* Site Header:33 */
.MES33 {
padding: 30px 0;

@media #{$medium-up} {
padding: 0 0 20px 0;

}
@media #{$large-up} {
padding: 0 0 30px 0;

}
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL20;}
& > hr {border-top-style: solid;}
 }
/* Go Back Button:32 */
.MES32 {
background-color: $COL1;
&:hover { background-color: $COL18;}
color: $COL20;
&:hover { color: $COL2;}
font-size: 18px;
border-radius: 0 20px;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: $COL20;
 }
/* Main Content:31 */
.MES31 {
background-color: $COL2;
color: $COL22;
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
.MES31 {
background-color: $COL2;
color: $COL22;
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
a.MEC31 { color: $COL39;
&:hover { color: $COL27; }
 }
cite.MEC31{
color: $COL22;
}
/* side menu:30 */
nav.me-Menu.MES30 {
.menu-item.MEC30{ &:hover {background-color: $COL13}
}
& .menu-item.MEC30, & .menu-item.MEC30 > div.MEC30{ & > a.MEC30{color: $COL19;
font-size: 14.4px;
text-transform: capitalize;
}
 &:hover > a.MEC30{color: $COL19;
}
 }
&.horizontal > .menu-item.MEC30 { border:0;
border-color: $COL13;
border-style: solid;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC30 { border:0;
border-color: $COL13;
border-style: solid;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC30 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC30 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* stock button:29 */
.MES29 {
background-color: $COL18;
color: $COL14;
 }
.MES29 {
background-color: $COL18;
color: $COL14;
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: $COL14;
 }
 }
a.MEC29 { color: $COL2;
&:hover { color: $COL15; }
 }
cite.MEC29{
color: $COL14;
}
/* Content wrap:28 */
.MES28 {
background-color: $COL2;
padding: 0;

 }
.MES28 {
background-color: $COL2;
padding: 0;

 }
a.MEC28 { color: $COL22;
&:hover { color: $COL39; }
 }
/* Enquire Button Gold:26 */
.MES26 {
background-color: $COL39;
&:hover { background-color: $COL18;}
color: $COL2;
&:hover { color: $COL2;}
font-size: 18px;
border-radius: 0 15px;
padding: 10px;

border-width: 2px;
border-style: solid;
border-color: $COL2;
 }
/* News Blocks:19 */
.MES19 {
background-color: $COL14;
padding: 0 0 10px 0;

border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent $COL13 transparent;
 }
.MES19 {
background-color: $COL14;
padding: 0 0 10px 0;

border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent $COL13 transparent;
 }
/* product body:25 */
.MES25 {
background-color: $COL22;
color: $COL14;
 }
.MES25 {
background-color: $COL22;
color: $COL14;
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: $COL15;
 }
 }
cite.MEC25{
color: $COL14;
}
/* Home Slider:24 */
.MES24 {
& .slider-arrow {background-color: $COL24;
color: $COL22;
font-size: 35px;
@media #{$medium-up} {
font-size: 35px;
};
@media #{$large-up} {
font-size: 35px;
};
}& .slider-arrow:hover{color: $COL21;
}& .slick-dots{bottom:30px;
} }
/* Sold:23 */
.MES23 {
background-color: $COL40;
color: $COL12;
padding: 20px;

 }
.MES23 {
background-color: $COL40;
color: $COL12;
padding: 20px;

h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: $COL12;
 }
 }
cite.MEC23{
color: $COL12;
}
/* Home Block Strip:22 */
.MES22 {
background-color: $COL22;
color: $COL14;
 }
.MES22 {
background-color: $COL22;
color: $COL14;
h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: $COL14;
 }
 }
cite.MEC22{
color: $COL14;
}
/* Buying and Selling:21 */
.MES21 {
background-color: $COL13;
&:hover, &.hover { background-color: $COL13;}
color: $COL22;
font-size: 16px;
padding: 10px;

 }
.MES21 {
background-color: $COL13;
&:hover, &.hover { background-color: $COL13;}
color: $COL22;
font-size: 16px;
padding: 10px;

 }
a.MEC21 { color: $COL22;
&:hover { color: $COL39; }
 }
cite.MEC21{
color: $COL22;
font-size: 16px;
}
/* Home Carousel:20 */
.MES20 {
& .slider-arrow {color: $COL18;
font-size: 10px;
@media #{$medium-up} {
font-size: 20px;
};
@media #{$large-up} {
font-size: 20px;
};
}& .slider-arrow:hover{color: $COL21;
}& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: $COL28;
border-radius: 50%;
border-style:solid;
border-width:5px;
@media #{$medium-up} {
border-width: 5px;
};
@media #{$large-up} {
border-width: 5px;};
&:hover{border-color: $COL28;
;}
background-color: $COL25;
background-clip: padding-box;
&:hover {background-color: $COL39;}
width:20px;
height:20px;
@media #{$medium-up} {
width:20px;
height:20px;
};
@media #{$large-up} {
width:20px;
height:20px;
};
&:hover{background-color: $COL39;
;}}}
& .slick-dots .slick-active {
  button{
border-color: $COL28;
background-color: $COL39;
  }
}
& .slick-dots{bottom:100px;
@media #{$medium-up} {
bottom: 75px;};
@media #{$large-up} {
bottom: 80px;};
} }
/* Responsive menu style:69 */
nav.responsive-menu {
.menu-item.MEC69{background-color: $COL16;
}
& .menu-item.MEC69, & .menu-item.MEC69 > div.menu-item-wrap{ & > a.MEC69, & > i{color: $COL2;
text-align: left;
}
  }
& .menu-item.MEC69 { border:0;
border-color: $COL18;
border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 3px 20px;}

& .sub-menu{.menu-item.MEC69{background-color: $COL14;
}
& .menu-item.MEC69, & .menu-item.MEC69 > div.menu-item-wrap{ & > a.MEC69, & > i{color: $COL31;
text-align: left;
}
  }
 .sub-menu {}}

 }
