//.row{max-width: 1280px;}
.ugly-start {
  display: none;
}

.me-block.row .site-home-block-wrap.me-block.row {
  @media #{$large-up} {
    @include strech();
  }
}

.me-FilterContent.site-home-block-wrap > .content-body {
  margin-left: -10px;
  margin-right: -10px;
  max-width: none;
  width: auto;
  @media #{$medium-up} {
    @include strech();
  }
}

.site-home-block {
  border: 1px solid transparent;
  &:hover {
    border: 1px solid #ccc;
  }
}

.feature-coin {
  .me-Hamle {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    width: 100%;
    bottom: 0;
    display: none;
    color: white;
    justify-content: center;
    align-items: center;
    a {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      color: white;
      align-items: center;
      padding: 10px;
    }
  }
  &:hover {
    .me-Hamle {
      display: flex;
    }
  }
}

.me-block.me-FilterContent.coin-type-wrap {
  & > .row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
    max-width: none;
    width: auto;
  }
}

.carousel-wrap {
  overflow: hidden;
  & > .column {
    margin: 0 -15px;
    max-width: calc(100% + 30px);
    .slick-list.draggable {
      width: 100%;
      .slick-track {
        display: flex;
      }
    }
  }
}

.coin-type {
  .me-block.me-MultiCol {
    position: absolute;
    height: 100%;
    width: 100%;
    display: none;
    top: 0;
  }
  &:hover .me-block.me-MultiCol {
    display: flex;
    cite {
      color: white;
      line-height: 1.5rem;
      max-width: 180px;
      margin: 0 auto;
    }
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  display: none;
}
.me-StickyPanel[data-type='head'] + div {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}
.me-StickyPanel nav.me-Menu.horizontal {
  & > .menu-item {
    a {
      font-weight: bolder;
    }
  }
}

.me-ContentArea nav.me-Menu.horizontal {
  @media #{$small-only} {
    flex-direction: column;
    align-items: center;
    .menu-item {
      width: 100%;
      a {
        background: #dddddd;
        width: 100%;
        max-width: 70%;
        margin: 0 auto;
        text-align: center;
        border-radius: 0 15px;
        border: 1px solid white;
      }
    }
  }
  & > .menu-item.active {
    & > a {
      font-weight: bolder;
    }
  }
}

.home-carousel.me-block.me-Carousel {
  .slider-arrow {
    display: none;
  }
  @media screen and (min-width: 1280px) {
    .slider-arrow {
      display: block;
      &.slider-arrow-left {
        left: -40px;
      }
      &.slider-arrow-right {
        right: -40px;
      }
    }
  }
}
.me-PanelCol.secWrap {
  position: absolute;
  transform: translateY(-100%);
  left: 0;
  @media screen and (max-width: 1023px) {
    display: none !important;
  }
}

.home-slider {
  .slick-dotted {
    margin-bottom: 0;
  }
  .slider-arrow {
    width: 40px;
    height: 60px;
    justify-content: center;
  }
  .mask-left,
  .mask-right {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 9;
  }
  .mask-left {
    left: 0;
  }
  .mask-right {
    right: 0;
  }
}

//.enquire-button{
//  position: relative;
//  right: 0;
//  max-width: 250px;
//  z-index: 1;
//  @media screen and (min-width: 640px) {position: absolute;}
//  @media screen and (max-width: 640px) {
//    text-align: center;
//    .me-Hamle{margin-top: 15px;}
//  }
//}

.backview {
  cursor: pointer;
}

.single-prod .backview {
  position: absolute;
  right: 20px;
  bottom: 20px;
  max-width: 150px;
  padding: 5px;
  background: white;
}

@media #{$small-only} {
  .mobile-backview-frame {
    position: absolute;
    bottom: 5px;
    right: 5px;
    max-width: 100px;
  }
}

#filter {
  background-color: #333333;
  display: inline-block;
  max-width: 80%;
  border: 1px solid #666666;
  color: #f2f2f2;
  font-family: PT Serif;
  font-size: 11px;
  height: 27px;
  padding: 5px;
  margin: 0;
}

.right-menu {
  & > .row {
    border: 1px dotted #333333;
    .column.item {
      padding: 15px;
      border-bottom: 1px dotted #333333;
      a {
        color: #333333;
      }
      &:hover {
        background-color: #333333;
        a {
          color: white;
        }
      }
    }
  }
}

.me-Video {
  figure img {
    width: 100%;
  }
}

.back-to-list a {
  font-size: 14px;
  display: block;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  color: #b7904b;
  @media screen and (min-width: 640px) {
    font-size: 16px;
  }
}

table {
  border-collapse: separate;
  border-spacing: 1px;
}
.coinworks-table {
  border-spacing: 0;
  border-collapse: separate;
}
.coinworks-table tr:nth-child(odd) {
  background: #e8e8e8;
}
.coinworks-table img {
  width: 100%;
  max-width: 200px;
}
.coinworks-table td:nth-child(2) {
  vertical-align: middle;
}

.no-style {
  border: 0;
  background: transparent;
  tbody,
  tr,
  tr:nth-child(even),
  tr:nth-child(odd),
  td {
    background: transparent;
    border: 0;
    vertical-align: middle;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

hr {
  margin: 0;
}

.heronews {
  @media screen and (min-width: 640px) {
    position: relative;
    .me-block.me-Field {
      margin-top: 0;
      a {
        display: none;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        cursor: pointer;
        padding: 0 30px;
        h4,
        h5,
        h6 {
          color: white;
        }
      }
    }
    &:hover .me-block.me-Field a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 600px) {
  table.responsive {
    border: 0;
  }

  table.responsive thead {
    display: none;
  }

  table.responsive tr {
    margin-bottom: 10px;
    display: block;
    border-bottom: 2px solid #ddd;
  }

  table.responsive td {
    display: block;
    text-align: right;
    font-size: 13px;
    border-bottom: 1px dotted #ccc;
  }

  table.responsive td:last-child {
    border-bottom: 0;
  }

  table.responsive td:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.feature-coins {
  .me-PanelCol {
    .me-PanelCol {
      position: absolute;
      display: none;
      padding: 10px;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
    }
    & > .panel-link {
      z-index: 9;
    }
    &:hover {
      .me-PanelCol,
      .me-CKEditor {
        display: block;
        color: white;
        cite {
          color: #fff;
          line-height: 1.5rem;
          max-width: 180px;
          margin: 0 auto;
        }
      }
      .me-CKEditor {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
      & > .overlay {
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @media all and (-ms-high-contrast: none) {
          z-index: 1;
        }
      }
    }
  }
}

.me-block.row {
  .latest-catalogue,
  .side-align {
    & > .row {
      @media #{$large-up} {
        @include strech();
      }
    }
  }
  .row.side-align {
    @media #{$large-up} {
      @include strech();
    }
  }
}

.view-e-catalogue {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
}

.me-block.me-Button {
  a.button:before {
    content: '';
    display: block;
    padding-top: 4px;
  }
}
.slick-slide {
  width: 100%;
}

.top-search {
  position: absolute;
  max-width: 0;
  overflow: hidden;
  left: 0;
  transition: max-width 1s ease;
  top: 50%;
  transform: translateY(-50%);
  &.show {
    max-width: 300px;
  }
}
.top-search-icon {
  cursor: pointer;
}

#read-more {
  display: none;
}

.resp-table {
  tr:nth-child(even) td:first-child {
    background-color: #f1f1f1;
  }
  td:first-child {
    position: sticky;
    left: 0;
    background: white;
  }
}

.navigation,
.pagination {
  .small.button {
    margin: 0;
    @media #{$medium-up} {
      margin: 0 5px;
    }
    background: #e8e8e8;
    border-radius: 5px;
    font-size: 14px;
  }
  span.small.button {
    color: #999999;
  }
}

.navigation {
  display: grid;
  grid-template-rows: 40px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  @media #{$medium-up} {
    display: none;
  }
  .page,
  .sel-page,
  .ellipses {
    display: none;
  }
  .back {
    grid-row: 1;
    grid-column: 1;
  }
  .all {
    grid-row: 1;
    grid-column: 2;
  }
  .forward {
    grid-row: 1;
    grid-column: 3;
  }
}

.pagination {
  text-align: center;
  .small.button {
    &.sel-page {
      background-color: #afbd21;
      color: white;
    }
    &.ellipses {
      background: transparent;
      color: inherit;
      cursor: default;
    }
  }

  @media #{$small-only} {
    display: flex;
    justify-content: space-around;
    // display: grid;
    // grid-template-rows: 40px;
    // grid-template-columns: repeat(auto-fill, 40px);
    // grid-gap: 5px;
    .small.button {
      &.back,
      &.forward,
      &.all {
        display: none;
      }
    }
  }
}
